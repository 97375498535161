<template>
    <div>
        <template v-if="config.sidebarPanelConfigurations && config.sidebarPanelConfigurations.length > 1">
            <div class="sidebar-panel">
                <v-tabs color="primary" style="padding: 0;background-color: red;" @change="tabChange">
                    <v-tab v-for="panel in config.sidebarPanelConfigurations" v-bind:key="panel.name" style="background-color: white;">
                        {{translate(panel)}}
                    </v-tab>
                    <v-tab-item v-for="panel in config.sidebarPanelConfigurations" v-bind:key="panel.name"
                                style="padding-top: 32px;">
                        <component :panel-data="panel.panelData" :is="panel.elementName"
                                   :componentWidth="config.portalUIConfiguration.navigationSideBarWidth"/>
                    </v-tab-item>
                </v-tabs>
            </div>
        </template>
        <template v-if="config.sidebarPanelConfigurations && config.sidebarPanelConfigurations.length === 1">
            <component :panel-data="config.sidebarPanelConfigurations[0].panelData" :is="config.sidebarPanelConfigurations[0].elementName" :componentWidth="config.portalUIConfiguration.navigationSideBarWidth"/>
        </template>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import AssetContainerNavigation from '@/components/AssetContainerNavigation.vue'
import AssetFilterSidebar from '@/components/AssetFilterSidebar.vue'

export default {
    name: "MultiPanelSidebar",
    components: {AssetFilterSidebar, AssetContainerNavigation},
    methods: {
        tabChange(event) {
            this.$store.state.assets.activeSidePanelIndex = event
            this.$store.commit("search/RESET_ALL_SEARCH_VALUES")
        },
        translate(panel){
            if(panel.translate){
                return this.$i18n.t(panel.name)
            }
            return panel.name
        }
    },
    // created() {
    //     this.$store.state.assets.searchPanelType = this.$store.state.config.server.sidebarPanelConfigurations[0].elementName
    // },
    computed: {
        ...mapState({
            config: state => state.config.server,
        }),
    }
}
</script>

<style>

.sidebar-panel .v-tab {
    font-size: 0.7em;
    padding: 0 8px;
    min-width: 60px;
}

.sidebar-panel .v-tabs-bar {
    height: 32px;
    position: fixed;
    /*top: 4rem;*/
    z-index: 2;
}

.sidebar-panel .v-slide-group__prev {
    min-width: 6px;
}

.sidebar-panel .v-slide-group__next {
    min-width: 6px;
}
</style>